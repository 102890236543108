import React from 'react';
import AppRoutes from 'navigations/routes';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { SessionProvider } from 'contexts/session';
import { ToastStoreProvider } from 'contexts/toast';
import yupPtBr from 'config/yupPtBr';
import { setLocale } from 'yup';
import moment from 'moment';
import 'moment/locale/pt-br';

setLocale(yupPtBr);
moment.locale('pt');

declare const document: any;

const isRunningOnDevicePlayer = Boolean(document.getUniqueMachineId);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.name === 'NetworkUnauthorizedError') {
        window.location.href = isRunningOnDevicePlayer
          ? '/device/login'
          : '/logout';
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error.name === 'NetworkUnauthorizedError') {
        window.location.href = isRunningOnDevicePlayer
          ? '/device/login'
          : '/logout';
      }
    }
  })
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <ToastStoreProvider>
          <AppRoutes />
        </ToastStoreProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
}

export default App;
