import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import UrlExternal from 'models/UrlExternal';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import NumberField from 'components/NumberField';
import RadioChoicesFiled from 'components/RadioChoicesField';

const yupSchema = yup.object({
  url: yup.string().required('A url é obrigatória.'),
  duration: yup.number(),
  isIndefinite: yup.boolean().required()
});

const UrlExternalFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching } = useApiGet<UrlExternal>(
    `/media-manager/url-external`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    UrlExternal
  >('/media-manager/url-external');
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    UrlExternal
  >('/media-manager/url-external', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/media-manager/url-external',
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const urlExternal = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const isIndefiniteValue = useWatch({
    control: formMethods.control,
    name: 'isIndefinite',
    defaultValue: true
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: UrlExternal) => {
      if (!userCanPerformAction) return;
      if (isIndefiniteValue) {
        data.duration = 0;
      }
      mutate(data, {
        onError,
        onSuccess: () => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'URL salva com sucesso'
          });
          navigate('/media-manager/url-external');
        }
      });
    },
    [
      userCanPerformAction,
      mutate,
      onError,
      addToast,
      navigate,
      isIndefiniteValue
    ]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir o URL'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'URL excluído com sucesso'
        });
        navigate('/media-manager/url-external');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (urlExternal) {
      formMethods.reset({
        url: urlExternal.url,
        duration: urlExternal.duration,
        isIndefinite: urlExternal.isIndefinite
      } as any);
    }
  }, [urlExternal, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <span className="h5 fw-bolder mb-0">URLs</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row xs="1" className="d-flex align-items-center">
              <Col>
                <TextField
                  name="url"
                  label="Digite a URL:"
                  placeholder="URL"
                  disabled={!userCanPerformAction}
                  defaultValue={urlExternal?.url}
                />
              </Col>
              <Col>
                <RadioChoicesFiled
                  options={[
                    { label: 'Sim', value: false },
                    { label: 'Não', value: true }
                  ]}
                  name="isIndefinite"
                  label="Possui tempo de duração?"
                  checked={urlExternal?.isIndefinite}
                  disabled={!userCanPerformAction}
                  classNameRadio="d-flex"
                />
              </Col>
              <Col>
                <NumberField
                  name="duration"
                  label="Duração:"
                  placeholder="Duração"
                  disabled={isIndefiniteValue}
                  defaultValue={urlExternal?.duration}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir a URL? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};
export default UrlExternalFormPage;
