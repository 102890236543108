import React, { useEffect, useRef, useState } from 'react';
import ScreenOrientation from 'enum/ScreenOrientation';
import InternalsNews from 'models/InternalsNews';
import classNames from 'classnames';
import QRCode from 'qrcode.react';

type InternalsNewsTemplatesProps = {
  title: string;
  description: string;
  photoCredit: string;
  bgImage: string;
  logo?: string;
  qrCode?: string;
  color?: string;
  className?: string;
  news?: InternalsNews;
};

const Template1 = ({
  title,
  description,
  logo,
  photoCredit,
  bgImage,
  qrCode,
  color,
  className,
  news
}: InternalsNewsTemplatesProps) => {
  const aspectRatioClass =
    news?.screenOrientation === ScreenOrientation.PORTRAIT
      ? 'aspect-ratio-box-9-16-news'
      : 'aspect-ratio-box-16-9-news';

  const logoContainerRef = useRef<HTMLDivElement | null>(null);

  const hexToRgb = (hex: string) => {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `${r}, ${g}, ${b}`;
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (logoContainerRef.current) {
      const rgbColor = color ? hexToRgb(color) : 'transparent';
      logoContainerRef.current.style.setProperty('--logo-bg-color', rgbColor);
    }
  }, [color]);

  return (
    <div className={classNames(aspectRatioClass, className)}>
      <img
        className={classNames('media-content', 'w-100', { loaded: isLoaded })}
        src={bgImage}
        alt="Imagem de fundo"
        onLoad={handleLoad}
      />
      <div
        ref={logoContainerRef}
        className={classNames('container-logo-title-description')}
      >
        {logo && (
          <div className={classNames('logo')}>
            <img src={logo} alt="Logo" width={70} />
          </div>
        )}
        <div className={classNames('title-description')}>
          <div className={classNames('title-container')}>
            <h1 className={classNames('title')}>{title}</h1>
          </div>
          <div className={classNames('description-container')}>
            <p className={classNames('description')}>{description}</p>
          </div>
        </div>
      </div>
      <div className={classNames('photo-credit')}>
        <p>Foto: {photoCredit}</p>
      </div>
      <div className={classNames('qrCode')}>
        {qrCode && <QRCode value={qrCode} />}
      </div>
    </div>
  );
};

export { Template1 };
