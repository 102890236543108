import React, { useEffect, useMemo, useState } from 'react';
import { useApiPostFile } from 'hooks/useApi';
import Button from 'components/Button';
import { Progress } from 'reactstrap';
import FileUpload from 'types/FileUpload';
import { CanceledError } from 'axios';
import { isString } from 'lodash';

type MediaUploaderProps = {
  fileUpload: FileUpload;
  onUploadStatusChange: (file: FileUpload) => void;
  onUploaded: (file: FileUpload) => void;
  onClose: () => void;
};

const validateFileType = (file: File) => {
  const allowedTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/bmp',
    'video/mp4',
    'video/x-msvideo',
    'video/quicktime',
    'video/x-matroska',
    'video/mpeg'
  ];
  return allowedTypes.includes(file.type);
};

const MediaUploader = ({
  onUploadStatusChange,
  fileUpload: propsFileUpload,
  onUploaded,
  onClose
}: MediaUploaderProps) => {
  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const { mutate, progress, abort } = useApiPostFile('/media-manager/medias');
  useEffect(() => {
    if (propsFileUpload && !fileUpload) {
      setFileUpload(propsFileUpload);
    }
  }, [fileUpload, propsFileUpload]);
  useEffect(() => {
    setFileUpload((file) =>
      file ? { ...file, progress: progress } : undefined
    );
  }, [progress]);
  useEffect(() => {
    if (fileUpload && fileUpload.status !== 'waiting') {
      onUploadStatusChange(fileUpload);
      if (fileUpload.status === 'uploaded') {
        onUploaded(fileUpload);
      }
    }
  }, [fileUpload, onUploadStatusChange, onUploaded]);

  useEffect(() => {
    if (fileUpload && fileUpload.status === 'waiting') {
      if (!validateFileType(fileUpload.file)) {
        fileUpload.errorMessage = 'Tipo de arquivo inválido';
        fileUpload.status = 'error';
        setFileUpload(fileUpload);
        return;
      }

      fileUpload.status = 'uploading';
      const formData = new FormData();
      formData.append('file', fileUpload.file);
      if (fileUpload.extraData) {
        Object.entries(fileUpload.extraData).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value));
        });
      }
      mutate(formData as any, {
        onSuccess: () => {
          fileUpload.status = 'uploaded';
          setFileUpload(fileUpload);
        },
        onError: (e: any) => {
          if (e instanceof CanceledError) {
            fileUpload.status = 'canceled';
          } else {
            const { errors } = e;
            try {
              if (errors) {
                if (errors.status === 422) {
                  fileUpload.errorMessage = errors.detail['file'];
                } else {
                  fileUpload.errorMessage = isString(errors.detail)
                    ? errors.detail
                    : 'Erro ao enviar arquivo';
                }
              }
            } catch {
              fileUpload.errorMessage = 'Erro ao enviar arquivo';
            }
            fileUpload.status = 'error';
          }
          setFileUpload(fileUpload);
        }
      });
    }
  }, [fileUpload, mutate, onUploadStatusChange, onUploaded]);
  const closeButton = useMemo(() => {
    return (
      <Button
        icon="x"
        color="link"
        className="text-primary  p-0"
        onClick={onClose}
      />
    );
  }, [onClose]);

  const cancelButton = useMemo(() => {
    return (
      <Button
        icon="x-circle"
        color="link"
        className="text-danger p-0"
        onClick={abort}
      />
    );
  }, [abort]);

  return (
    <div className="d-flex justify-content-between align-items-center border rounded border-1 border-gray mb-1 p-2">
      {fileUpload?.status === 'waiting' && (
        <>
          <p className="mb-0 text-truncate text-nowrap">
            Processando {fileUpload?.file.name}
          </p>
          {cancelButton}
        </>
      )}
      {fileUpload?.status === 'uploading' && (
        <>
          <div className="w-75">
            <p className="mb-0 text-truncate text-nowrap">
              {fileUpload?.file.name}
            </p>
            <Progress value={fileUpload.progress} />
          </div>
          {cancelButton}
        </>
      )}
      {fileUpload?.status === 'uploaded' && (
        <>
          <p className="mb-0 text-truncate text-nowrap">
            Enviado {fileUpload?.file.name}
          </p>
          {closeButton}
        </>
      )}
      {fileUpload?.status === 'error' && (
        <>
          <p className="mb-0 text-danger">
            Error ao enviar {fileUpload?.file.name}
            <br />
            <small>{fileUpload?.errorMessage}</small>
          </p>
          {closeButton}
        </>
      )}
      {fileUpload?.status === 'canceled' && (
        <>
          <p className="mb-0 text-truncate text-nowrap">
            Envio cancelado {fileUpload?.file.name}
          </p>
          {closeButton}
        </>
      )}
    </div>
  );
};
export default MediaUploader;
