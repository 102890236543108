import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import React from 'react';
import DashboardPage from 'pages/DashboardPage';
import AuthLayout from 'layouts/AuthLayout';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import DashboardLayout from 'layouts/DashboardLayout';
import SignupPage from 'pages/SignUpPage';
import AccessPoliciesListPage from 'pages/AccessPolicies/AccessPoliciesListPage';
import AccessPoliciesFormPage from 'pages/AccessPolicies/AccessPoliciesFormPage';
import AuthenticatedRoute from 'navigations/AuthenticatedRequired';
import NonAuthenticatedRequired from 'navigations/NonAuthenticatedRequired';
import LoginPage from 'pages/Login/LoginPage';
import MediaManagerListPage from 'pages/MediaManager/MediaManagerListPage';
import CustomerUsersListPage from 'pages/CustomerUsers/CustomerUsersListPage';
import CustomerUsersFormPage from 'pages/CustomerUsers/CustomerUsersFormPage';
import CustomerCompaniesListPage from 'pages/CustomerCompanies/CustomerCompaniesListPage';
import CustomerCompaniesFormPage from 'pages/CustomerCompanies/CustomerCompaniesFormPage';
import UserProfileForm from 'pages/UserProfileForm';
import SupportFaqListPage from 'pages/SupportFaqListPage';
import HistoryLogsListPage from 'pages/HistoryLogsListPage';
import CampaignListPage from 'pages/Campaign/CampaignListPage';
import CampaignCreatePage from 'pages/Campaign/CampaignCreatePage';
import CampaignEditPage from 'pages/Campaign/CampaignEditPage';
import DeviceGroupListPage from 'pages/DeviceGroup/DeviceGroupListPage';
import DeviceGroupFormPage from 'pages/DeviceGroup/DeviceGroupFormPage';
import DeviceListPage from 'pages/Device/DeviceListPage';
import DeviceFormPage from 'pages/Device/DeviceFormPage';
import DevicePage from 'pages/Device/DevicePage';
import PlaylistListPage from 'pages/Playlist/PlaylistListPage';
import PlaylistCreatePage from 'pages/Playlist/PlaylistCreatePage';
import PlaylistEditPage from 'pages/Playlist/PlaylistEditPage';
import NewsListPage from 'pages/InternalsNews/InternalsNewsListPage';
import InternalsNewsFormPage from 'pages/InternalsNews/InternalsNewsFormPage';
import DashboardLayoutAdm from 'layouts/DashboardLayoutAdm';
import DashboardPageAdm from 'pages/DashboardPageAdm';
import useUser from 'hooks/useUser';
import UserRole from 'enum/UserRole';
import Logout from 'components/Logout';
import AdminNotificationsListPage from 'pages/AdminNotifications/AdminNotificationsListPage';
import AdminNotificationsFormPage from 'pages/AdminNotifications/AdminNotificationsFormPage';
import LoginDevicePage from 'pages/Login/LoginDevicePage';
import DeviceAttachPage from 'pages/Device/DeviceAttachPage';
import AdminLicensesListPage from 'pages/AdminLicenses/AdminLicensesListPage';
import AdminLicensesFormPage from 'pages/AdminLicenses/AdminLicensesFormPage';
import { DeviceProvider } from 'contexts/device';
import AdminUsersListPage from 'pages/AdminUsers/AdminUsersListPage';
import AdminUsersFormPage from 'pages/AdminUsers/AdminUsersFormPage';
import UrlExternalListPage from 'pages/Url/UrlExternalListPage';
import UrlExternalFormPage from 'pages/Url/UrlExternalFormPage';

export const ROUTES_PATHS = {
  AUTH_PAGES: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    SIGNUP: '/signup',
    LOGOUT: '/logout',
    LOGIN_DEVICE: '/device/login'
  },
  LOGGED_PAGES: {
    DASHBOARD: '/',
    ACCESS_POLICIES: {
      LIST: '/access-policies',
      CREATE: '/access-policies/create',
      EDIT: '/access-policies/:id'
    },
    CUSTOMER_USERS: {
      LIST: '/customer/users',
      CREATE: '/customer/users/create',
      EDIT: '/customer/users/:id'
    },
    MEDIA_MANAGER: {
      LIST: '/media-manager'
    },
    CUSTOMER_COMPANIES: {
      LIST: '/customer/companies',
      CREATE: '/customer/companies/create',
      EDIT: '/customer/companies/:id'
    },
    USER_PROFILE: {
      EDIT: '/customer/profile'
    },
    SUPPORT: {
      LIST: '/support/faq'
    },
    HISTORY_LOGS: {
      LIST: '/customer/logs/history'
    },
    CAMPAIGN: {
      LIST: '/media-manager/campaigns',
      CREATE: '/media-manager/campaigns/create',
      EDIT: '/media-manager/campaigns/:id'
    },
    DEVICE_GROUP: {
      LIST: '/device/group',
      CREATE: '/device/group/create',
      EDIT: '/device/group/:id'
    },
    DEVICE: {
      LIST: '/device',
      CREATE: '/device/create',
      EDIT: '/device/:id'
    },
    PLAYLIST: {
      LIST: '/media-manager/playlists',
      CREATE: '/media-manager/playlists/create',
      EDIT: '/media-manager/playlists/:id'
    },
    NEWS: {
      LIST: '/news/internals',
      CREATE: '/news/internals/create',
      EDIT: '/news/internals/:id'
    },
    ADMIN: {
      DASHBOARD: '/adm',
      LOGIN: '/login',
      NOTIFICATIONS: {
        LIST: '/admin/notifications',
        CREATE: '/admin/notifications/create',
        EDIT: '/admin/notifications/:id'
      },
      LICENCES: {
        LIST: '/admin/licenses',
        CREATE: '/admin/licenses/create',
        EDIT: '/admin/licenses/:id'
      },
      USERS: {
        LIST: '/admin/auth/users',
        CREATE: '/admin/auth/users/create',
        EDIT: '/admin/auth/users/:id'
      }
    },
    DEVICE_ATTACH: {
      LIST: '/device/attach'
    },
    URL: {
      LIST: '/media-manager/url-external',
      CREATE: '/media-manager/url-external/create',
      EDIT: '/media-manager/url-external/:id'
    }
  }
};

const nonAuthenticatedRoutes = () => {
  return (
    <>
      <Route
        path={ROUTES_PATHS.AUTH_PAGES.LOGIN}
        element={
          <NonAuthenticatedRequired>
            <AuthLayout>
              <LoginPage />
            </AuthLayout>
          </NonAuthenticatedRequired>
        }
      />
      <Route
        path={ROUTES_PATHS.AUTH_PAGES.FORGOT_PASSWORD}
        element={
          <NonAuthenticatedRequired>
            <AuthLayout>
              <ForgotPasswordPage />
            </AuthLayout>
          </NonAuthenticatedRequired>
        }
      />
      <Route
        path={ROUTES_PATHS.AUTH_PAGES.SIGNUP}
        element={
          <NonAuthenticatedRequired>
            <AuthLayout>
              <SignupPage />
            </AuthLayout>
          </NonAuthenticatedRequired>
        }
      />
      <Route path={ROUTES_PATHS.AUTH_PAGES.LOGOUT} Component={Logout} />
    </>
  );
};

const authenticatedRoutes = () => {
  return (
    <Route
      path="*"
      element={
        <AuthenticatedRoute loginPath={ROUTES_PATHS.AUTH_PAGES.LOGIN}>
          <DashboardLayout routes={ROUTES_PATHS.LOGGED_PAGES}>
            <Routes>
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DASHBOARD}
                element={<DashboardPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ACCESS_POLICIES.LIST}
                element={<AccessPoliciesListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ACCESS_POLICIES.CREATE}
                element={<AccessPoliciesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ACCESS_POLICIES.EDIT}
                element={<AccessPoliciesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.MEDIA_MANAGER.LIST}
                element={<MediaManagerListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_USERS.LIST}
                element={<CustomerUsersListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_USERS.CREATE}
                element={<CustomerUsersFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_USERS.EDIT}
                element={<CustomerUsersFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_COMPANIES.LIST}
                element={<CustomerCompaniesListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_COMPANIES.CREATE}
                element={<CustomerCompaniesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CUSTOMER_COMPANIES.EDIT}
                element={<CustomerCompaniesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.USER_PROFILE.EDIT}
                element={<UserProfileForm />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.SUPPORT.LIST}
                element={<SupportFaqListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.HISTORY_LOGS.LIST}
                element={<HistoryLogsListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CAMPAIGN.LIST}
                element={<CampaignListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CAMPAIGN.CREATE}
                element={<CampaignCreatePage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.CAMPAIGN.EDIT}
                element={<CampaignEditPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE_GROUP.LIST}
                element={<DeviceGroupListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE_GROUP.CREATE}
                element={<DeviceGroupFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE_GROUP.EDIT}
                element={<DeviceGroupFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE.LIST}
                element={<DeviceListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE.CREATE}
                element={<DeviceFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.DEVICE.EDIT}
                element={<DevicePage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.PLAYLIST.LIST}
                element={<PlaylistListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.PLAYLIST.CREATE}
                element={<PlaylistCreatePage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.PLAYLIST.EDIT}
                element={<PlaylistEditPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.NEWS.LIST}
                element={<NewsListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.NEWS.CREATE}
                element={<InternalsNewsFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.NEWS.EDIT}
                element={<InternalsNewsFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.URL.LIST}
                element={<UrlExternalListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.URL.CREATE}
                element={<UrlExternalFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.URL.EDIT}
                element={<UrlExternalFormPage />}
              />
            </Routes>
          </DashboardLayout>
        </AuthenticatedRoute>
      }
    />
  );
};

const authenticatedRoutesAdm = () => {
  return (
    <Route
      path="*"
      element={
        <AuthenticatedRoute loginPath={ROUTES_PATHS.AUTH_PAGES.LOGIN}>
          <DashboardLayoutAdm routes={ROUTES_PATHS.LOGGED_PAGES}>
            <Routes>
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.DASHBOARD}
                element={<DashboardPageAdm />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.NOTIFICATIONS.LIST}
                element={<AdminNotificationsListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.NOTIFICATIONS.CREATE}
                element={<AdminNotificationsFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.NOTIFICATIONS.EDIT}
                element={<AdminNotificationsFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.LICENCES.LIST}
                element={<AdminLicensesListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.LICENCES.CREATE}
                element={<AdminLicensesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.LICENCES.EDIT}
                element={<AdminLicensesFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.USERS.LIST}
                element={<AdminUsersListPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.USERS.CREATE}
                element={<AdminUsersFormPage />}
              />
              <Route
                path={ROUTES_PATHS.LOGGED_PAGES.ADMIN.USERS.EDIT}
                element={<AdminUsersFormPage />}
              />
            </Routes>
          </DashboardLayoutAdm>
        </AuthenticatedRoute>
      }
    />
  );
};

const routesDevice = () => {
  return (
    <>
      <Route
        path={ROUTES_PATHS.AUTH_PAGES.LOGIN_DEVICE}
        element={<LoginDevicePage />}
      />

      <Route
        path={ROUTES_PATHS.LOGGED_PAGES.DEVICE_ATTACH.LIST}
        element={
          <DeviceProvider>
            <DeviceAttachPage />
          </DeviceProvider>
        }
      />
    </>
  );
};

const AppRoutes = () => {
  const user = useUser();

  return (
    <Router>
      <Routes>
        {nonAuthenticatedRoutes()}
        {user?.role === UserRole.ADMIN
          ? authenticatedRoutesAdm()
          : authenticatedRoutes()}
        {routesDevice()}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
