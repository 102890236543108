import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiGet, useApiPost } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import { Playlist } from 'models/Playlist';
import useUser from 'hooks/useUser';
import SwitchField from 'components/SwitchField';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';
import RadioChoicesFiled from 'components/RadioChoicesField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';
import DateField from 'components/DateField';

type FormValue = {
  name: string;
  active: boolean;
  deviceGroup: Option | null;
  screenOrientation: ScreenOrientation;
  endDate: string;
  startDate: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  deviceGroup: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  active: yup.boolean()
});

const PlaylistCreatePage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching } = useApiGet<Playlist>(
    `/media-manager/playlists`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Playlist>(
    '/media-manager/playlists'
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>('/device/group');

  const mutate = mutateCreate;

  const userCanPerformAction = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const isSaving = isCreating;

  const playlist = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      deviceGroup: null,
      screenOrientation: ScreenOrientation.LANDSCAPE
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        deviceGroup: data.deviceGroup?.value!,
        name: data.name,
        active: data.active,
        screenOrientation: data.screenOrientation,
        startDate: data.startDate,
        endDate: data.endDate
      } as any;
      mutate(payload, {
        onError,
        onSuccess: (payload) => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Playlist salva com sucesso'
          });
          navigate(`/media-manager/playlists/${payload.id}`);
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (device) =>
        ({
          label: device.name,
          value: device.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  useEffect(() => {
    if (playlist) {
      formMethods.reset({
        name: playlist.name,
        active: playlist.active,
        screenOrientation: playlist.screenOrientation,
        deviceGroup: playlist.deviceGroup
          ? {
              label: playlist.deviceGroup?.name,
              value: playlist.deviceGroup?.id
            }
          : null,
        startDate: playlist.startDate,
        endDate: playlist.endDate
      } as any);
    }
  }, [playlist, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/media-manager/playlists'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Playlists</span>
              </p>
            </Col>
            <Col className="text-end">
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row xs="2" className="d-flex align-items-center">
              <Col>
                <TextField
                  name="name"
                  label="Nome da playlist:"
                  placeholder="Nome da playlist"
                  disabled={!userCanPerformAction}
                  defaultValue={playlist?.name}
                />
              </Col>
              <Col>
                <AsyncTypeaheadField
                  isLoading={isLoadingDeviceGroup}
                  name="deviceGroup"
                  label="Grupo:"
                  options={deviceGroupOptions}
                  onSearch={(search: string) =>
                    deviceGroupRefetch({
                      params: { search }
                    } as any)
                  }
                  disabled={!userCanPerformAction}
                  multiple={false}
                />
              </Col>
              <Col>
                <DateField
                  name="startDate"
                  label="Data de inicio de execução (Opcional)"
                  type="datetime-local"
                />
              </Col>
              <Col>
                <DateField
                  name="endDate"
                  label="Data de fim de execução (Opcional)"
                  type="datetime-local"
                />
              </Col>
              <Col>
                <RadioChoicesFiled
                  label="Orientação:"
                  name="screenOrientation"
                  options={typeOptionsOrientation}
                  classNameRadio="d-flex"
                />
              </Col>
              <Col>
                <SwitchField
                  name="active"
                  label="Ativo"
                  disabled={!userCanPerformAction}
                  checked={playlist?.active}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </>
  );
};
export default PlaylistCreatePage;
