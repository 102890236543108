import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import { MediaFileAndCampaignPlaylist, Playlist } from 'models/Playlist';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import SwitchField from 'components/SwitchField';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';
import RadioChoicesFiled from 'components/RadioChoicesField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';
import PlaylistItemsPage from './PlaylistItemsPage';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import PlaylistList from 'components/Playlist/PlaylistList';
import MediaEditor, {
  EditedMedia,
  MediaEditorRef
} from 'components/MediaEditor';
import MediaFile from 'models/MediaFile';
import { Campaign } from 'models/MediaFileCampaign';
import ModalPlaylistsPreview from 'components/ModalPlaylistsPreview';
import PlayerPlaylistPreview from 'components/Playlist/PlayerPlaylistPreview';
import InternalsNews from 'models/InternalsNews';
import UrlExternal from 'models/UrlExternal';
import DateField from 'components/DateField';

type FormValue = {
  name: string;
  active: boolean;
  deviceGroup: Option | null;
  screenOrientation: ScreenOrientation;
  startDate: string;
  endDate: string;
};

type MutateMediaFileAndCampaignPlaylist = Omit<
  MediaFileAndCampaignPlaylist,
  'campaign' | 'internalNews' | 'externalUrl'
> & {
  mediaFile?: any;
  campaign?: string;
  internalNews?: string;
  externalUrl?: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  deviceGroup: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  active: yup.boolean()
});

const PlaylistEditPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const refMediaEditor = useRef<MediaEditorRef>(null);

  const { data, isLoading: isFetching, refetch } = useApiGet<Playlist>(
    `/media-manager/playlists`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Playlist>(
    '/media-manager/playlists'
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<Playlist>(
    '/media-manager/playlists',
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/media-manager/playlists',
    params.id
  );

  const {
    mutate: mutatePlaylistItems,
    isPending: isUpdatingPlaylistItems
  } = useApiPut<Playlist>(
    `/media-manager/playlists/${params.id}/items/orders`,
    '',
    {
      onSuccess: refetch
    }
  );

  const {
    mutate: mutateCreatePlaylistItems,
    isPending: isCreatingPlaylistItems
  } = useApiPost<MutateMediaFileAndCampaignPlaylist>(
    `/media-manager/playlists/${params.id}/items`
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>('/device/group');

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const playlist = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      deviceGroup: null,
      screenOrientation: ScreenOrientation.LANDSCAPE
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        deviceGroup: data.deviceGroup?.value!,
        name: data.name,
        active: data.active,
        screenOrientation: data.screenOrientation,
        startDate: data.startDate,
        endDate: data.endDate
      } as any;
      mutate(payload, {
        onError,
        onSuccess: (payload) => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Playlist salva com sucesso'
          });
          navigate(`/media-manager/playlists`);
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir a playlist'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Playlist excluída com sucesso'
        });
        navigate('/media-manager/playlists');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (device) =>
        ({
          label: device.name,
          value: device.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  const handleOnEditMedia = useCallback(
    (mediaFile: MediaFile) => {
      refMediaEditor?.current?.showEditor(mediaFile, {
        orientation: playlist?.screenOrientation!
      });
    },
    [playlist?.screenOrientation]
  );

  const handleReOrderMedias = useCallback(
    (items: MediaFileAndCampaignPlaylist[]) => {
      mutatePlaylistItems(
        items.map((item) => ({
          itemId: item.id,
          order: item.order
        })) as any
      );
    },
    [mutatePlaylistItems]
  );

  const handleOnFinish = useCallback(
    (mediaFile: MediaFile, { url, cloudinaryJson }: EditedMedia) => {
      const newOrder = playlist?.items?.length || 0;
      mutateCreatePlaylistItems(
        {
          editedFile: url,
          order: newOrder || 0,
          duration: 5,
          cloudinaryJson,
          active: true,
          mediaFile: mediaFile.id
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, playlist, refetch]
  );

  const handleOnCampaignFinish = useCallback(
    (campaign: Campaign) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          campaign: campaign.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const handleOnInternalsNewsFinish = useCallback(
    (news: InternalsNews) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          internalNews: news.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const handleOnUrlExternalFinish = useCallback(
    (url: UrlExternal) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          externalUrl: url.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const [mediaToPreview, setMediaToPreview] = useState<
    MediaFileAndCampaignPlaylist
  >();

  useEffect(() => {
    if (playlist) {
      formMethods.reset({
        name: playlist.name,
        active: playlist.active,
        screenOrientation: playlist.screenOrientation,
        deviceGroup: playlist.deviceGroup
          ? {
              label: playlist.deviceGroup?.name,
              value: playlist.deviceGroup?.id
            }
          : null,
        startDate: playlist.startDate,
        endDate: playlist.endDate
      } as any);
    }
  }, [playlist, formMethods]);

  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = useCallback((playlist: Playlist) => {
    setSelectedPlaylist(playlist);
    setIsPreviewOpen(true);
  }, []);

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    setSelectedPlaylist(null);
  }, []);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/media-manager/playlists'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Playlists</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <Row xs="2" className="d-flex align-items-center w-100">
                <Col>
                  <TextField
                    name="name"
                    label="Nome da playlist:"
                    placeholder="Nome da playlist"
                    disabled={!userCanPerformAction}
                    defaultValue={playlist?.name}
                  />
                </Col>
                <Col>
                  <AsyncTypeaheadField
                    isLoading={isLoadingDeviceGroup}
                    name="deviceGroup"
                    label="Grupo:"
                    options={deviceGroupOptions}
                    onSearch={(search: string) =>
                      deviceGroupRefetch({
                        params: { search }
                      } as any)
                    }
                    disabled={!userCanPerformAction}
                    multiple={false}
                  />
                </Col>
                <Col>
                  <DateField
                    name="startDate"
                    label="Data de inicio de execução (Opcional)"
                    type="datetime-local"
                  />
                </Col>
                <Col>
                  <DateField
                    name="endDate"
                    label="Data de fim de execução (Opcional)"
                    type="datetime-local"
                  />
                </Col>
                <Col>
                  <RadioChoicesFiled
                    label="Orientação:"
                    name="screenOrientation"
                    options={typeOptionsOrientation}
                    classNameRadio="d-flex"
                    disabled
                  />
                </Col>
                <Col>
                  <SwitchField
                    name="active"
                    label="Ativo"
                    disabled={!userCanPerformAction}
                    checked={playlist?.active}
                  />
                </Col>
              </Row>
              <div className="d-flex">
                <Col md={6} className="me-4">
                  <PlaylistItemsPage
                    playlistScreenOrientation={playlist?.screenOrientation!}
                  />
                </Col>
                <Col md={6}>
                  <div className="p-3 mt-5">
                    <p className="pb-4 mb-5 d-flex align-items-center justify-content-between">
                      <span className="h5 fw-bolder mb-0">
                        Preview da Playlist
                      </span>
                      <Button
                        className="white ms-2 border-0"
                        color="danger"
                        type="button"
                        onClick={() => openPreview(playlist!)}
                      >
                        <Icon icon="play-btn-fill" className="me-3" />
                        Reproduzir Playlist
                      </Button>
                    </p>
                    <hr />
                    <p className="mb-3 text-body-secondary">
                      <Icon icon="house-door" className="me-1" /> Playlist
                    </p>
                    <PlaylistList
                      mediaItems={playlist?.items || []}
                      isLoading={
                        isUpdatingPlaylistItems ||
                        isCreatingPlaylistItems ||
                        isUpdating
                      }
                      onAddCampaign={handleOnCampaignFinish}
                      onAddMedia={handleOnEditMedia}
                      onAddInternalsNews={handleOnInternalsNewsFinish}
                      onAddUrlExternal={handleOnUrlExternalFinish}
                      onReOrderMedias={handleReOrderMedias}
                      playlistId={playlist?.id!}
                      onClick={(media: MediaFileAndCampaignPlaylist) =>
                        setMediaToPreview(media)
                      }
                    />
                  </div>
                  <MediaEditor onFinish={handleOnFinish} ref={refMediaEditor} />
                </Col>
              </div>
              {selectedPlaylist && (
                <PlayerPlaylistPreview
                  playlist={selectedPlaylist}
                  isOpen={isPreviewOpen}
                  onClose={closePreview}
                />
              )}
            </>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir o playlist? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <ModalPlaylistsPreview
        mediaPlaylistPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
    </>
  );
};

export default withDragAndDropProvider(PlaylistEditPage);
