import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import Icon from 'components/Icon';
import CustomerUser from 'models/CustomerUser';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import AccessPolicy from 'models/AccessPolicy';
import ImageUpload from 'components/ImageUpload';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import PasswordField from 'components/PasswordField';
import SwitchField from 'components/SwitchField';
import ImagePreview from 'components/ImagePreview';
import Pagination from 'models/Pagination';

const objetcSchema = {
  email: yup.string().email().required(),
  firstName: yup.string().required('O nome é obrigatório.'),
  lastName: yup.string().required('O sobrenome é obrigatório.'),
  principalUser: yup.boolean(),
  policies: yup.array(),
  password: yup.string().default(''),
  confirmPassword: yup.string().default('')
};
const yupSchemaEdit = yup.object(objetcSchema).required();

const yupSchemaCreate = yup.object({
  ...objetcSchema,
  password: yup.string().required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
});

const yupSchemaPassword = yup.object({
  password: yup.string().required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
});

const CustomerUsersCreatePage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [filteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data, isLoading: isFetching, refetch } = useApiGet<CustomerUser>(
    `/customer/users`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    CustomerUser
  >('/customer/users');
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    CustomerUser
  >('/customer/users', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/customer/users',
    params.id
  );

  const {
    data: accessPolicyData,
    refetch: accessPolicyRefetch,
    isLoading: isLoadingPolicies
  } = useApiGet<Pagination<AccessPolicy>>('/authorization/access-policies', {
    params: filteredData
  });

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const customerUsers = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(params.id ? yupSchemaEdit : yupSchemaCreate)
  });

  const formMethodsPassword = useForm({
    resolver: yupResolver(yupSchemaPassword)
  });

  const switchValue = useWatch({
    control: formMethods.control,
    name: 'principalUser',
    defaultValue: false
  });

  const onError = useFieldSetErrors(formMethods.setError);
  const onErrorPassword = useFieldSetErrors(formMethodsPassword.setError);

  const handleOnSuccess = useCallback(() => {
    addToast({
      color: 'success',
      icon: 'check',
      message: 'Usuário salvo com sucesso'
    });
    !params.id && navigate('/customer/users');
    params.id && setOpenPassword(false);
  }, [params.id, navigate, addToast]);

  const handleOnSubmit = useCallback(
    (data: any) => {
      if (!userCanPerformAction) return;
      const dataPolicies =
        data.policies && Array.isArray(data.policies)
          ? data.policies.map((policy: any) => policy.value)
          : [];

      if (params.id) {
        mutate(
          { ...data, policies: data.principalUser ? [] : dataPolicies },
          {
            onError,
            onSuccess: handleOnSuccess
          }
        );
      } else {
        setOpenPassword(true);
      }
    },
    [userCanPerformAction, params.id, mutate, onError, handleOnSuccess]
  );

  const handleSubmitModal = useCallback(
    (passwordFormData: any) => {
      formMethodsPassword.clearErrors();
      const formData = formMethods.getValues();

      if (params.id) {
        const newData = {
          ...passwordFormData,
          ...customerUsers,
          policies: customerUsers?.policies.map((policy: any) => policy.id)
        };
        mutate(newData as any, {
          onError: (error: any) => {
            onError(error);
            onErrorPassword(error);
          },
          onSuccess: handleOnSuccess
        });
      } else {
        const dataPolicies =
          formData.policies && Array.isArray(formData.policies)
            ? formData.policies.map((policy: any) => policy.value)
            : [];

        const combinedData = {
          ...formData,
          ...passwordFormData,
          policies: formData.principalUser ? [] : dataPolicies
        };

        mutate(combinedData as any, {
          onError: (error: any) => {
            onError(error);
            onErrorPassword(error);
          },
          onSuccess: handleOnSuccess
        });
      }
    },
    [
      mutate,
      onError,
      handleOnSuccess,
      onErrorPassword,
      formMethods,
      formMethodsPassword,
      params.id,
      customerUsers
    ]
  );

  const handleSavePassword = useCallback(() => {
    formMethodsPassword.clearErrors();
    formMethodsPassword.handleSubmit(handleSubmitModal)();
  }, [formMethodsPassword, handleSubmitModal]);

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir usuário'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Usuário excluído com sucesso'
        });
        navigate('/customer/users');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const accessPoliciesOptions = useMemo(() => {
    const data = accessPolicyData?.items || [];
    return data.map((policy) => ({
      label: policy.name,
      value: policy.id
    }));
  }, [accessPolicyData]);

  useEffect(() => {
    if (customerUsers) {
      formMethods.reset({
        firstName: customerUsers.firstName,
        lastName: customerUsers.lastName,
        email: customerUsers.email,
        isActive: customerUsers.isActive,
        principalUser: customerUsers.principalUser,
        policies: customerUsers.principalUser
          ? []
          : customerUsers.policies.map((policy: any) => ({
              label: policy.name,
              value: policy.id
            }))
      } as any);
    }
  }, [customerUsers, formMethods]);

  const handleClickOpen = () => {
    setOpenPassword(true);
  };

  const handleClose = () => {
    setOpenPassword(false);
  };

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/customer/users'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Usuários</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {params.id && userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  color={params.id ? 'secondary' : 'primary'}
                  icon={params.id ? 'eye-fill' : 'floppy'}
                  onClick={handleClickOpen}
                  className="ms-2"
                >
                  {params.id ? 'Alterar senha' : 'Continuar'}
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row>
              <Col className="col-sm-12 col-md-6 offset-md-3">
                <div className="mb-3">
                  {params.id && userCanPerformAction && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <ImagePreview imageUrl={customerUsers?.picture} />
                      <ImageUpload
                        url={`/customer/users/${params.id}/picture`}
                        onSuccess={() => refetch()}
                        keyName="picture"
                      />
                    </div>
                  )}
                </div>
                <TextField
                  name="firstName"
                  label="Nome:"
                  placeholder="Nome"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.firstName}
                />
                <TextField
                  name="lastName"
                  label="Sobrenome:"
                  placeholder="Sobrenome"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.lastName}
                />
                <TextField
                  name="email"
                  label="Email:"
                  placeholder="Email"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.email}
                />
                <div className="mb-3">
                  <SwitchField
                    name="principalUser"
                    label="Super Usuário"
                    disabled={!userCanPerformAction}
                  />
                </div>
                <div className="mb-3">
                  <AsyncTypeaheadField
                    isLoading={isLoadingPolicies}
                    name="policies"
                    label="Políticas de acesso:"
                    options={accessPoliciesOptions}
                    onSearch={(search: string) =>
                      accessPolicyRefetch({
                        params: { search }
                      } as any)
                    }
                    disabled={!userCanPerformAction || switchValue}
                    multiple
                  />
                </div>
                <SwitchField
                  name="isActive"
                  label="Usuário ativo"
                  disabled={!userCanPerformAction}
                  checked={customerUsers?.isActive}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir o usuário? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <Form onSubmit={() => {}} {...formMethodsPassword}>
        <Modal
          primaryButtonType="submit"
          primaryButtonAction={handleSavePassword}
          primaryButtonLabel="Salvar"
          title="Cadastre sua senha"
          isOpen={openPassword}
          showCloseButton={true}
          toggle={handleClose}
          isLoading={isSaving}
        >
          <PasswordField
            label="Senha:"
            type="password"
            name="password"
            placeholder="Senha"
          />
          <PasswordField
            label="Confirme a senha:"
            type="password"
            name="confirmPassword"
            placeholder="Confirme a senha"
          />
        </Modal>
      </Form>
    </>
  );
};
export default CustomerUsersCreatePage;
