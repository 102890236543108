import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import AdminUser from 'models/AdminUser';

const AdminUsersListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: UsersPage, isLoading } = useApiGet<Pagination<AdminUser>>(
    '/admin/auth/users',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/admin/auth/users/${id}`);
    },
    [navigate]
  );

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Usuários</span>
          </p>
        </Col>
        <Col className="text-end">
          <Link to={'/admin/auth/users/create'} className="btn btn-primary">
            <Icon icon="plus" />
            Adicionar Novo
          </Link>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar usuário"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {UsersPage?.items.map((users) => (
            <tr key={users.id} role="button" onClick={() => goToEdit(users.id)}>
              <td>
                <small>Nome: </small>
                <br />
                <b>{users.firstName}</b>
              </td>
              <td>
                <small>Email: </small>
                <br />
                <b>{users.email}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(UsersPage?.total) && (
        <p className="text-end">{UsersPage?.total} usuário(s) encontrado(s)</p>
      )}
      {UsersPage && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={UsersPage.totalPages}
          currentPage={UsersPage.page}
        />
      )}
    </div>
  );
};

export default AdminUsersListPage;
