import { Col, Row, Spinner } from 'reactstrap';
import React, { useCallback } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import PageSizeSelector from 'components/PageSizeSelector';
import UrlExternal from 'models/UrlExternal';
import UrlExternalItem from 'components/UrlExternal/UrlExternalItem';

const UrlExternalListDragPage = () => {
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const { data: urlExternal, isLoading } = useApiGet<Pagination<UrlExternal>>(
    '/media-manager/url-external',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">URL Externa</span>
          </p>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar notícias"
          />
        </Col>
        <Col className="text-end mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <div className="table-campaign-drag border">
        {isLoading && (
          <tr>
            <td colSpan={2} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        )}
        {urlExternal?.items?.map((url) => (
          <UrlExternalItem key={url.id} urlExternal={url} />
        ))}
      </div>
      {!isLoading && urlExternal?.total && (
        <p className="text-end">
          {urlExternal?.total} campanha(s) encontrada(s)
        </p>
      )}
      {urlExternal && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={urlExternal.totalPages}
          currentPage={urlExternal.page}
        />
      )}
    </div>
  );
};

export default UrlExternalListDragPage;
