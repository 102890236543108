// @ts-ignore
import { useDrag } from 'react-dnd';
import UrlExternal from 'models/UrlExternal';
import { Table } from 'reactstrap';

type UrlExternalItemProps = {
  urlExternal: UrlExternal;
};

const UrlExternalItem = ({ urlExternal }: UrlExternalItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'UrlExternal',
    item: { id: urlExternal.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <tr ref={drag}>
      <Table>
        {!isDragging ? (
          <tr className="campaign-medias-list-item">
            <td>
              <tr className="d-flex justify-content-between align-items-center">
                <td className="w-100 me-4">
                  <small>URL: </small>
                  <br />
                  <b>{urlExternal.url}</b>
                </td>
                <td className="w-100 mx-4">
                  <small>Duração: </small>
                  <br />
                  <b>
                    {urlExternal.isIndefinite
                      ? 'Tempo indeterminado'
                      : urlExternal.duration + ' segundos'}
                  </b>
                </td>
              </tr>
            </td>
          </tr>
        ) : (
          <tr className="campaign-medias-list-item">
            <td className="bg-dark-subtle"></td>
          </tr>
        )}
      </Table>
    </tr>
  );
};

export default UrlExternalItem;
