import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import UrlExternal from 'models/UrlExternal';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';

function UrlExternalListPage() {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: urlData, isLoading } = useApiGet<Pagination<UrlExternal>>(
    '/media-manager/url-external',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/media-manager/url-external/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Url Externa</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link
              to={'/media-manager/url-external/create'}
              className="btn btn-primary"
            >
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar url"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {urlData?.items?.map((url) => (
            <tr>
              <div className="d-flex d-flex justify-content-between align-items-center">
                <div
                  className="d-flex justify-content-between align-items-center w-100"
                  key={url.id}
                  role="button"
                  onClick={() => goToEdit(url.id ?? '')}
                >
                  <td className="w-100">
                    <small>URL: </small>
                    <br />
                    <b>{url.url}</b>
                  </td>
                  <td className="w-100">
                    <small>Duração: </small>
                    <br />
                    <b>
                      {url.isIndefinite
                        ? 'Tempo indeterminado'
                        : url.duration + ' segundos'}
                    </b>
                  </td>
                </div>
              </div>
            </tr>
          ))}
        </tbody>
      </Table>
      {!isLoading && urlData?.total && (
        <p className="text-end">{urlData?.total} campanha(s) encontrada(s)</p>
      )}
      {urlData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={urlData.totalPages}
          currentPage={urlData.page}
        />
      )}
    </div>
  );
}

export default UrlExternalListPage;
