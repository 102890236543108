import { MediaFileAndCampaignPlaylist } from 'models/Playlist';
import React, { useCallback, useMemo, useState } from 'react';
// @ts-ignore
import { useDrag, useDrop } from 'react-dnd';
import { Input, Table } from 'reactstrap';
import { useApiDelete, useApiPut, useInvalidateQuery } from 'hooks/useApi';
import { useToast } from 'hooks/useToast';
import Button from 'components/Button';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import Icon from 'components/Icon';
import { startsWith } from 'lodash';
import Modal from 'components/Modal';

type PlaylistItemProps = {
  dragAndDropKeyAccept: string;
  mediaItems: MediaFileAndCampaignPlaylist;
  playlistId: string;
  index: number;
  onMediaReOrderFinish: () => void;
  onMediaReOrderPreview: (dragId: string, hoverId: string) => void;
  onClick?: (mediaItems: MediaFileAndCampaignPlaylist) => void;
  isFirst?: boolean;
  isLast?: boolean;
  moveUp: (itemId: string) => void;
  moveDown: (itemId: string) => void;
};

type DragAndDropOrderType = { itemId: string };
type DragOrderPreviewType = { isDragging: boolean };

const PlaylistItem = ({
  mediaItems,
  onMediaReOrderFinish,
  onMediaReOrderPreview,
  dragAndDropKeyAccept,
  onClick,
  playlistId,
  index,
  isFirst,
  isLast,
  moveUp,
  moveDown
}: PlaylistItemProps) => {
  const user = useUser();
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [editedTime, setEditedTime] = useState(mediaItems.duration || 0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate, isPending } = useApiPut<MediaFileAndCampaignPlaylist>(
    `/media-manager/playlists/${playlistId}/items/${mediaItems.id}`
  );
  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    `/media-manager/playlists/${playlistId}/items/${mediaItems.id}`
  );
  const userCanEdit = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.UPDATE
    );
  }, [user]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const handleTimeClick = useCallback(
    (duration: number) => {
      if (!userCanEdit) return;
      setIsEditingTime(true);
      setEditedTime(duration);
    },
    [userCanEdit]
  );
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTime(parseInt(e.target.value));
  };

  const { addToast } = useToast();

  const { invalidate } = useInvalidateQuery(['/media-manager/playlists']);

  const handleTimeSave = useCallback(() => {
    mutate(
      {
        duration: editedTime,
        order: mediaItems.order,
        active: mediaItems.active
      },
      {
        onSuccess: () => {
          invalidate();
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Tempo atualizado com sucesso.'
          });
        },
        onError: (error: any) => {
          const { errors } = error || ({} as any);
          const message =
            errors?.status === 422 ? errors?.detail?.duration : errors?.detail;
          addToast({
            icon: 'exclamation-triangle',
            color: 'danger',
            message: message || 'Erro ao atualizar o tempo.'
          });
        }
      }
    );
    setIsEditingTime(false);
  }, [
    editedTime,
    mutate,
    mediaItems.order,
    mediaItems.active,
    addToast,
    invalidate
  ]);

  const handleTimeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTimeSave();
    } else if (e.key === 'Escape') {
      setIsEditingTime(false);
    }
  };

  const [{ isDragging }, drag] = useDrag<
    DragAndDropOrderType,
    DragAndDropOrderType,
    DragOrderPreviewType
  >(() => ({
    type: dragAndDropKeyAccept,
    item: { itemId: mediaItems.id! },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [, drop] = useDrop<DragAndDropOrderType>(
    {
      accept: dragAndDropKeyAccept,
      hover(item) {
        onMediaReOrderPreview(item.itemId, mediaItems.id!);
      },
      drop() {
        onMediaReOrderFinish();
      }
    },
    [onMediaReOrderPreview, mediaItems.id, onMediaReOrderFinish]
  );

  const handleMediaClick = useCallback(() => {
    onClick?.(mediaItems);
  }, [mediaItems, onClick]);

  const handleOnDelete = useCallback(() => {
    if (!userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir o item.'
        });
      },
      onSuccess: () => {
        invalidate();
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Item excluído com sucesso'
        });
      }
    });
  }, [addToast, mutateDelete, userCanDelete, invalidate]);

  const handleMoveUp = useCallback(() => {
    moveUp(mediaItems.id!);
    onMediaReOrderFinish();
  }, [moveUp, mediaItems.id, onMediaReOrderFinish]);

  const handleMoveDown = useCallback(() => {
    moveDown(mediaItems.id!);
    onMediaReOrderFinish();
  }, [moveDown, mediaItems.id, onMediaReOrderFinish]);

  return (
    <tr ref={drop}>
      <td ref={drag}>
        <Table>
          {!isDragging ? (
            <tr className="campaign-medias-list-item">
              {mediaItems.mediaFile && (
                <>
                  <td width={50}>
                    <div className="d-flex flex-column">
                      {isFirst ? null : (
                        <Button
                          data-testid="move-up"
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-up-square"
                          onClick={handleMoveUp}
                        />
                      )}
                      {isLast ? null : (
                        <Button
                          data-testid="move-down"
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-down-square"
                          onClick={handleMoveDown}
                        />
                      )}
                    </div>
                  </td>
                  <td width={200}>
                    <small>Ordem de execução: </small>
                    <br />
                    <b>{index + 1}</b>
                  </td>
                  <td width={200}>
                    <small>Tempo: </small>
                    <br />
                    {startsWith(mediaItems.mediaFile?.mineType, 'image') &&
                      (!isEditingTime ? (
                        <span
                          role="button"
                          onClick={() =>
                            handleTimeClick(mediaItems?.duration || 0)
                          }
                        >
                          <b>{mediaItems?.duration} segundos</b>
                          {userCanEdit && (
                            <Icon icon="pencil" className="ms-1" />
                          )}
                        </span>
                      ) : (
                        <span>
                          <Input
                            className="w-50"
                            type="number"
                            value={editedTime}
                            onChange={handleTimeChange}
                            onKeyDown={handleTimeKeyDown}
                          />
                          <span className="d-flex justify-content-end w-50">
                            <Button
                              type="button"
                              color="primary"
                              className="mt-1 ps-1 pb-1 pt-0 pe-0"
                              icon="check-lg"
                              loading={isPending}
                              onClick={handleTimeSave}
                            ></Button>
                            <Button
                              type="button"
                              color="danger"
                              className="mt-1 ms-1 ps-1 pb-1 pt-0 pe-0"
                              icon="x-lg"
                              onClick={() => setIsEditingTime(false)}
                            ></Button>
                          </span>
                        </span>
                      ))}
                    {startsWith(mediaItems.mediaFile?.mineType, 'video') && (
                      <b>{mediaItems?.duration} segundos</b>
                    )}
                  </td>
                  <td>
                    <div
                      role="button"
                      onClick={handleMediaClick}
                      className="img-thumbnail"
                    >
                      <img
                        alt={mediaItems.mediaFile.name}
                        src={mediaItems.thumbnail}
                        className="m-auto d-block"
                      />
                    </div>
                  </td>
                </>
              )}
              {mediaItems.campaign && (
                <>
                  <td width={50}>
                    <div className="d-flex flex-column">
                      {isFirst ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-up-square"
                          onClick={handleMoveUp}
                        />
                      )}
                      {isLast ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-down-square"
                          onClick={handleMoveDown}
                        />
                      )}
                    </div>
                  </td>
                  <td width={200}>
                    <small>Ordem de execução: </small>
                    <br />
                    <b>{index + 1}</b>
                  </td>
                  <td width={200}>
                    <small>Tempo: </small>
                    <br />
                    <span>
                      <b>
                        {mediaItems.campaign?.medias?.reduce(
                          (acc, media) => acc + (Number(media.duration) || 0),
                          0
                        )}{' '}
                        segundos
                      </b>
                    </span>
                  </td>
                  <td>
                    <small>Campanha: </small>
                    <br />
                    <b>{mediaItems.campaign.name}</b>
                  </td>
                </>
              )}
              {mediaItems.internalNews && (
                <>
                  <td width={50}>
                    <div className="d-flex flex-column">
                      {isFirst ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-up-square"
                          onClick={handleMoveUp}
                        />
                      )}
                      {isLast ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-down-square"
                          onClick={handleMoveDown}
                        />
                      )}
                    </div>
                  </td>
                  <td width={200}>
                    <small>Ordem de execução: </small>
                    <br />
                    <b>{index + 1}</b>
                  </td>
                  <td width={200}>
                    <small>Tempo: </small>
                    <br />
                    {!isEditingTime ? (
                      <span
                        role="button"
                        onClick={() =>
                          handleTimeClick(mediaItems?.duration || 0)
                        }
                      >
                        <b>{mediaItems?.duration} segundos</b>
                        {userCanEdit && <Icon icon="pencil" className="ms-1" />}
                      </span>
                    ) : (
                      <span>
                        <Input
                          className="w-50"
                          type="number"
                          value={editedTime}
                          onChange={handleTimeChange}
                          onKeyDown={handleTimeKeyDown}
                        />
                        <span className="d-flex justify-content-end w-50">
                          <Button
                            type="button"
                            color="primary"
                            className="mt-1 ps-1 pb-1 pt-0 pe-0"
                            icon="check-lg"
                            loading={isPending}
                            onClick={handleTimeSave}
                          ></Button>
                          <Button
                            type="button"
                            color="danger"
                            className="mt-1 ms-1 ps-1 pb-1 pt-0 pe-0"
                            icon="x-lg"
                            onClick={() => setIsEditingTime(false)}
                          ></Button>
                        </span>
                      </span>
                    )}
                  </td>
                  <td>
                    <small>Notícia: </small>
                    <br />
                    <b>{mediaItems.internalNews.title}</b>
                  </td>
                </>
              )}
              {mediaItems.externalUrl && (
                <>
                  <td width={50}>
                    <div className="d-flex flex-column">
                      {isFirst ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-up-square"
                          onClick={handleMoveUp}
                        />
                      )}
                      {isLast ? null : (
                        <Button
                          color="transparent"
                          type="button"
                          className="border-0 p-0 button-order"
                          icon="caret-down-square"
                          onClick={handleMoveDown}
                        />
                      )}
                    </div>
                  </td>
                  <td width={200}>
                    <small>Ordem de execução: </small>
                    <br />
                    <b>{index + 1}</b>
                  </td>
                  <td width={200}>
                    <small>Tempo: </small>
                    <br />
                    {!isEditingTime ? (
                      <span
                        role="button"
                        onClick={() =>
                          handleTimeClick(mediaItems?.duration || 0)
                        }
                      >
                        <b>{mediaItems?.duration} segundos</b>
                        {userCanEdit && <Icon icon="pencil" className="ms-1" />}
                      </span>
                    ) : (
                      <span>
                        <Input
                          className="w-50"
                          type="number"
                          value={editedTime}
                          onChange={handleTimeChange}
                          onKeyDown={handleTimeKeyDown}
                        />
                        <span className="d-flex justify-content-end w-50">
                          <Button
                            type="button"
                            color="primary"
                            className="mt-1 ps-1 pb-1 pt-0 pe-0"
                            icon="check-lg"
                            loading={isPending}
                            onClick={handleTimeSave}
                          ></Button>
                          <Button
                            type="button"
                            color="danger"
                            className="mt-1 ms-1 ps-1 pb-1 pt-0 pe-0"
                            icon="x-lg"
                            onClick={() => setIsEditingTime(false)}
                          ></Button>
                        </span>
                      </span>
                    )}
                  </td>
                  <td>
                    <small>URL Externa: </small>
                    <br />
                    <b className="text-truncate-wrap">
                      {mediaItems.externalUrl.url}
                    </b>
                  </td>
                </>
              )}
              <td width={80}>
                {userCanDelete && (
                  <Button
                    type="button"
                    color="danger"
                    loading={isDeleting}
                    icon="trash"
                    className="ms-4 border-0 pe-0 button-delete"
                    onClick={() => setShowDeleteConfirmation(true)}
                  ></Button>
                )}
              </td>
            </tr>
          ) : (
            <tr className="campaign-medias-list-item">
              <td colSpan={4} className="bg-dark-subtle"></td>
            </tr>
          )}
        </Table>
        <Modal
          primaryButtonAction={handleOnDelete}
          primaryButtonLabel="Excluir"
          title="Atenção!"
          isOpen={showDeleteConfirmation}
          showCloseButton={false}
          toggle={() => setShowDeleteConfirmation((x) => !x)}
          secondaryButtonAction={() => setShowDeleteConfirmation(false)}
          secondaryButtonLabel="Cancelar"
        >
          <p>
            Desaja realmente excluir o item? <br />
            <small>Essa alteração não poderá ser revertida.</small>
          </p>
        </Modal>
      </td>
    </tr>
  );
};

export default PlaylistItem;
