import React, { useMemo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useController } from 'react-hook-form';
import moment from 'moment';

type Props = any & {
  name: string;
  label?: string;
  defaultValue?: any;
  type?: 'date' | 'time' | 'datetime-local';
};
const DateField = ({ name, label, defaultValue, type, ...props }: Props) => {
  const {
    field: { onChange, value, ...field },
    fieldState: { invalid }
  } = useController({ name, defaultValue: defaultValue || '' });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'datetime-local') {
      const date = moment(e.target.value).toISOString();
      onChange(date);
      return;
    }
    onChange(e.target.value);
  };

  const localValue = useMemo(() => {
    if (type === 'datetime-local') {
      return moment(value).local().format('YYYY-MM-DDTHH:mm');
    }
    return value;
  }, [value, type]);

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        data-testid={name}
        type={type || 'date'}
        {...props}
        {...field}
        invalid={invalid}
        value={localValue}
        onChange={handleOnChange}
      />
    </FormGroup>
  );
};

export default DateField;
