import React, { useCallback, useContext, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPut } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import Icon from 'components/Icon';
import { User } from 'models/User';
import ImageUpload from 'components/ImageUpload';
import PasswordField from 'components/PasswordField';
import ImagePreview from 'components/ImagePreview';
import SessionContext from 'contexts/session';

const yupSchema = yup
  .object({
    email: yup.string().email().required(),
    firstName: yup.string().required('O nome é obrigatório.'),
    lastName: yup.string().required('O sobrenome é obrigatório.')
  })
  .required();

const UserProfileForm = () => {
  const { user, setUser } = useContext(SessionContext);
  const { addToast } = useToast();

  const { mutate, isPending: isUpdating } = useApiPut<User>(
    '/customer/profile'
  );

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: User) => {
      mutate(data, {
        onError,
        onSuccess: (dataUser: User) => {
          setUser(dataUser);
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Alteração salva com sucesso'
          });
        }
      });
    },
    [addToast, mutate, onError, setUser]
  );

  useEffect(() => {
    if (user) {
      formMethods.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
      } as any);
    }
  }, [user, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/customer/users'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Perfil do Usuário</span>
              </p>
            </Col>
            <Col className="text-end">
              <Button color="primary" icon="floppy" loading={isUpdating}>
                Salvar
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="col-sm-12 col-md-6 offset-md-3">
              <div className="mb-3">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <ImagePreview imageUrl={user?.picture} />
                  <ImageUpload
                    url={`/customer/profile/picture`}
                    onSuccess={({ picture }: any) => {
                      setUser({ ...user, picture });
                    }}
                    keyName="picture"
                  />
                </div>
              </div>
              <TextField
                name="firstName"
                label="Nome:"
                placeholder="Nome"
                defaultValue={user?.firstName}
              />
              <TextField
                name="lastName"
                label="Sobrenome:"
                placeholder="Sobrenome"
                defaultValue={user?.lastName}
              />
              <TextField
                name="email"
                label="Email:"
                placeholder="Email"
                defaultValue={user?.email}
              />
              <PasswordField
                label="Senha:"
                type="password"
                name="password"
                placeholder="Senha"
              />
              <PasswordField
                label="Confirme a senha:"
                type="password"
                name="confirmPassword"
                placeholder="Confirme a senha"
              />
              <PasswordField
                label="Senha atual:"
                type="password"
                name="currentPassword"
                placeholder="Senha atual"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UserProfileForm;
