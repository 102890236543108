enum TypeResource {
  USERS = 'USERS',
  ACCESS_POLICIES = 'ACCESS_POLICIES',
  COMPANIES = 'COMPANIES',
  MEDIAS = 'MEDIAS',
  CAMPAIGNS = 'CAMPAIGNS',
  PLAYLISTS = 'PLAYLISTS',
  NEWS = 'NEWS',
  NEWS_CAT = 'CAT_NEWS',
  DEVICES = 'DEVICES',
  DEVICES_GROUPS = 'DEVICES_GROUPS',
  URL_EXTERNAL = 'EXTERNAL_URL'
}

export default TypeResource;
