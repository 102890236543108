import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { MediaFileAndCampaignPlaylist } from 'models/Playlist';
// @ts-ignore
import { useDrop } from 'react-dnd';
import PlaylistItem from './PlaylistItem';
import MediaFile from 'models/MediaFile';
import InternalsNews from 'models/InternalsNews';
import { Campaign } from 'models/MediaFileCampaign';
import UrlExternal from 'models/UrlExternal';
import { Card, Spinner, Table } from 'reactstrap';
import Icon from 'components/Icon';

type PlaylistListProps = {
  mediaItems: MediaFileAndCampaignPlaylist[];
  onAddMedia: (media: MediaFile) => void;
  onAddCampaign: (campaign: Campaign) => void;
  onAddInternalsNews: (internalsNews: InternalsNews) => void;
  onAddUrlExternal: (urlExternal: UrlExternal) => void;
  playlistId: string;
  onReOrderMedias: (mediaEdited: MediaFileAndCampaignPlaylist[]) => void;
  isLoading?: boolean;
  onClick?: (mediaItemPreview: MediaFileAndCampaignPlaylist) => void;
};

const PlaylistList = ({
  mediaItems,
  onAddMedia,
  onAddCampaign,
  onAddInternalsNews,
  onAddUrlExternal,
  onReOrderMedias,
  isLoading,
  onClick,
  playlistId
}: PlaylistListProps) => {
  const dragAndDropKeyAccept = `MediaPlaylistOrder`;

  const contentRef = useRef<HTMLDivElement>(null);
  const mediaItemsOrdered = useMemo(
    () => (mediaItems || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
    [mediaItems]
  );

  const [mediaItemsOrderedPreview, setMediaItemsOrderedPreview] = useState<
    MediaFileAndCampaignPlaylist[]
  >(mediaItemsOrdered);

  useEffect(() => {
    setMediaItemsOrderedPreview(mediaItemsOrdered);
  }, [mediaItemsOrdered]);

  const reorderMediaItems = useCallback(
    (dragId: string, hoverId: string) => {
      const draggedIndex = mediaItemsOrderedPreview.findIndex(
        (media) => media.id === dragId
      );
      const draggedMediaItem = mediaItemsOrderedPreview[draggedIndex];
      const hoverIndex = mediaItemsOrderedPreview.findIndex(
        (media) => media.id === hoverId
      );
      const copiedMediaItems = [...mediaItemsOrderedPreview];
      copiedMediaItems.splice(draggedIndex, 1);
      copiedMediaItems.splice(hoverIndex, 0, draggedMediaItem);
      return copiedMediaItems.map((mediaItem, index) => ({
        ...mediaItem,
        order: index
      }));
    },
    [mediaItemsOrderedPreview]
  );

  const handleMediaReOrderFinish = useCallback(() => {
    onReOrderMedias(mediaItemsOrderedPreview);
  }, [mediaItemsOrderedPreview, onReOrderMedias]);

  const handleMediaReOrderPreview = useCallback(
    (dragId: string, hoverId: string) => {
      setMediaItemsOrderedPreview(reorderMediaItems(dragId, hoverId));
    },
    [reorderMediaItems]
  );

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: ['MediaItem', 'Campaign', 'InternalsNews', 'UrlExternal'],
    drop(item: MediaFile | Campaign | InternalsNews, monitor) {
      const itemType = monitor.getItemType();
      if (itemType === 'MediaItem') {
        onAddMedia(item as MediaFile);
      } else if (itemType === 'Campaign') {
        onAddCampaign(item as Campaign);
      } else if (itemType === 'InternalsNews') {
        onAddInternalsNews(item as InternalsNews);
      } else {
        onAddUrlExternal(item as UrlExternal);
      }
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      return {
        isOver: monitor.isOver(),
        canDrop: item ? !(item as MediaFile).isFolder : false
      };
    }
  });

  const [{ isOverTop }, dropTopContent] = useDrop({
    accept: dragAndDropKeyAccept,
    collect: (monitor) => ({
      isOverTop: monitor.isOver()
    })
  });

  const [{ isOverBottom }, dropBottomContent] = useDrop({
    accept: dragAndDropKeyAccept,
    collect: (monitor) => ({
      isOverBottom: monitor.isOver()
    })
  });

  const SCROLL_INTERVAL = 20;

  useEffect(() => {
    if (isOverTop) {
      const interval = setInterval(() => {
        const content = contentRef.current;
        if (content && content.scrollTop > 0) {
          content.scrollTop -= SCROLL_INTERVAL;
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isOverTop]);

  useEffect(() => {
    if (isOverBottom) {
      const interval = setInterval(() => {
        const content = contentRef.current;
        if (content) {
          content.scrollTop += SCROLL_INTERVAL;
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isOverBottom]);

  const moveUp = useCallback(
    (mediaId: string) => {
      const currentIndex = mediaItemsOrderedPreview.findIndex(
        (media) => media.id === mediaId
      );
      if (currentIndex > 0) {
        const newIndex = currentIndex - 1;
        handleMediaReOrderPreview(
          mediaId,
          mediaItemsOrderedPreview[newIndex].id!
        );
      }
    },
    [handleMediaReOrderPreview, mediaItemsOrderedPreview]
  );

  const moveDown = useCallback(
    (mediaId: string) => {
      const currentIndex = mediaItemsOrderedPreview.findIndex(
        (media) => media.id === mediaId
      );
      if (currentIndex < mediaItemsOrderedPreview.length - 1) {
        const newIndex = currentIndex + 1;
        handleMediaReOrderPreview(
          mediaId,
          mediaItemsOrderedPreview[newIndex].id!
        );
      }
    },
    [handleMediaReOrderPreview, mediaItemsOrderedPreview]
  );

  return (
    <div ref={dropRef} className="h-100 mb-4">
      <div
        ref={dropTopContent}
        className="position-absolute p-4 end-0 start-0 z-1"
      />
      <Card
        color="light"
        className="playlist-medias-list"
        innerRef={contentRef}
      >
        {isLoading && (
          <div className="position-absolute end-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-3">
            <Spinner />
          </div>
        )}
        {isOver && !canDrop && (
          <div className="position-absolute start-0 end-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-3 bg-danger">
            <p>
              <Icon icon="ban" className="mr-2" />
              Somente arquivos de mídia são permitidos aqui!
            </p>
          </div>
        )}
        {isOver && canDrop && (
          <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center opacity-3 bg-danger-subtle">
            <p className="fw-bold text-black">
              <Icon icon="target" className="mr-2" />
              Solte o arquivo aqui!
            </p>
          </div>
        )}
        <Table>
          {mediaItemsOrderedPreview.map((media, index) => (
            <PlaylistItem
              dragAndDropKeyAccept={dragAndDropKeyAccept}
              key={media.id}
              mediaItems={media}
              onMediaReOrderFinish={handleMediaReOrderFinish}
              onMediaReOrderPreview={handleMediaReOrderPreview}
              onClick={onClick}
              playlistId={playlistId}
              index={index}
              isFirst={index === 0}
              isLast={index === mediaItemsOrderedPreview.length - 1}
              moveUp={moveUp}
              moveDown={moveDown}
            />
          ))}
        </Table>
      </Card>
      <div
        ref={dropBottomContent}
        className="position-absolute p-4 z-1 end-0 start-0"
      />
    </div>
  );
};

export default PlaylistList;
