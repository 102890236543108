import { Button, Col, Row } from 'reactstrap';
import React, { useCallback, useContext, useState } from 'react';
import logo from 'assets/images/logo.webp';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import SessionContext from 'contexts/session';
import { Link, useNavigate } from 'react-router-dom';
import PasswordField from 'components/PasswordField';
import RadioChoicesFiled from 'components/RadioChoicesField';
import CheckboxField from 'components/CheckboxField';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import TypeCustomer from 'enum/TypeCustomer';
import { User } from 'models/User';

const yupSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required('A senha é obrigatória.')
      .test(
        'password-strength',
        'A senha deve ter no mínimo 8 caracteres e conter ao menos uma letra.',
        (value) => {
          if (!value) return false;
          const hasMinLength = value.length >= 8;
          const hasLetter = /[a-zA-Z]/.test(value);
          return hasMinLength && hasLetter;
        }
      ),
    name: yup.string().required('O nome é obrigatório.'),
    cpfCnpj: yup
      .string()
      .required('O CPF ou CNPJ é obrigatório.')
      .min(
        11,
        'Se for CPF, deve ter no mínimo 11 caracteres. E se for CNPJ, deve ter no mínimo 14 caracteres.'
      ),
    confirmPassword: yup.string().required('Confirmar a senha é obrigatório.'),
    check: yup.boolean().oneOf([true], 'Este campo é obrigatório').required(),
    type: yup
      .mixed<TypeCustomer>()
      .oneOf(Object.values(TypeCustomer))
      .required()
  })
  .required();

const SignupPage = () => {
  const { setUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const [localUser, setLocalUser] = useState<User>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal);
  const typeOptions = [
    { value: TypeCustomer.PF, label: 'Pessoa Física' },
    { value: TypeCustomer.PJ, label: 'Pessoa Jurídica' }
  ];
  const methods = useForm({
    resolver: yupResolver(yupSchema)
  });
  const { control } = methods;

  const cpfCnpjType = useWatch({
    control,
    name: 'type',
    defaultValue: TypeCustomer.PF
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost<User>('/auth/signup');

  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(data, {
        onError,
        onSuccess: (response) => {
          setLocalUser(response);
          setShowSuccessModal(true);
        }
      });
    },
    [loginMutation, onError]
  );

  const redirectUser = useCallback(() => {
    setUser(localUser);
    navigate('/');
  }, [setUser, localUser, navigate]);

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100">
      <Col md={5} className="d-flex flex-column mx-auto">
        <img src={logo} alt="logo" className="rounded mx-auto d-block" />
        <p className="py-1">Registrar</p>
        <Form onSubmit={onSubmitHandler} {...methods}>
          <div className="d-flex justify-content-between">
            <p className="me-5">Eu sou</p>
            <div>
              <RadioChoicesFiled
                name="type"
                options={typeOptions}
                classNameRadio="d-flex"
              />
            </div>
          </div>
          <TextField
            label="Nome Completo"
            type="text"
            name="name"
            placeholder="Nome Completo"
          />
          <TextField
            label="Email"
            type="text"
            name="email"
            placeholder="Email"
          />
          <TextField
            label={cpfCnpjType === TypeCustomer.PF ? 'CPF' : 'CNPJ'}
            type="text"
            name="cpfCnpj"
            placeholder={cpfCnpjType === TypeCustomer.PF ? 'CPF' : 'CNPJ'}
            maxLength={cpfCnpjType === TypeCustomer.PF ? 11 : 14}
          />
          <PasswordField
            label="Senha"
            type="password"
            name="password"
            placeholder="Senha"
          />
          <PasswordField
            label="Confirme a senha"
            type="password"
            name="confirmPassword"
            placeholder="Confirme a senha"
          />
          <div className="py-2">
            <CheckboxField
              name="check"
              label={
                <span>
                  Ao se cadastrar você concorda com os nossos{' '}
                  <a href="*">termos de uso e privacidade</a>
                </span>
              }
            />
          </div>
          <div className="py-1">
            <Button
              color={methods.formState.isValid ? 'primary' : 'secondary'}
              size="lg"
              className="w-100"
              disabled={isPending}
            >
              Registrar
            </Button>
          </div>
        </Form>
        <div>
          <Modal
            classNameHeader="d-flex justify-content-center text-center"
            classNameFooter="d-flex justify-content-center"
            isOpen={showSuccessModal}
            toggle={toggleSuccessModal}
            title={
              <>
                <Icon
                  icon="check-circle"
                  width={50}
                  height={50}
                  className="text-success"
                />
                <p className="m-1">Sucesso!</p>
              </>
            }
            primaryButtonLabel="Ir para a home"
            primaryButtonAction={redirectUser}
            showCloseButton={false}
          >
            Seu usuário foi criado com sucesso. Você receberá em e-mail com as
            orientações para o primeiro acesso.
          </Modal>
        </div>
        <p className="pt-2">
          <Link to={'/login'} className="text-decoration-none small">
            Voltar para o início
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default SignupPage;
