import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { MediaFileAndCampaignPlaylist } from 'models/Playlist';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';
import InternalsNewsPreview from 'components/News/InternalsNewsPreview';

type MediaCampaignPreviewProps = {
  items: MediaFileAndCampaignPlaylist[];
  isOpen: boolean;
  onClose: () => void;
};

const second = 1000;

const MediaItemPreview = ({
  items,
  isOpen,
  onClose
}: MediaCampaignPreviewProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const timeoutRef = useRef<any>(null);

  const handleNextItem = useCallback(() => {
    const nextIndex = (currentIndex + 1) % items.length;
    setIsLoaded(false);
    setCurrentIndex(nextIndex);
  }, [currentIndex, items.length]);

  useEffect(() => {
    const currentItem = items[currentIndex];
    const mediaFile = currentItem.mediaFile;
    const isImage = mediaFile?.mineType?.startsWith('image');
    const externalUrl = currentItem.externalUrl;

    if (isImage || !externalUrl?.isIndefinite) {
      const duration = currentItem?.duration! * second;
      timeoutRef.current = setTimeout(handleNextItem, duration);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex, items, handleNextItem]);

  useEffect(() => {
    setIsLoaded(false);
  }, [currentIndex]);

  if (!items.length) return null;

  const currentItem = items[currentIndex];
  const mediaFile = currentItem.mediaFile;
  const campaign = currentItem.campaign;
  const internalNews = currentItem.internalNews;
  const isImage = mediaFile?.mineType?.startsWith('image');
  const externalUrl = currentItem.externalUrl;

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return mediaFile ? (
    isImage ? (
      <img
        src={currentItem.editedFile}
        alt="Media Preview"
        className={classNames('media-content', { loaded: isLoaded })}
        onLoad={handleLoad}
      />
    ) : (
      <video
        data-testid="video"
        src={currentItem.editedFile}
        onEnded={handleNextItem}
        onCanPlayThrough={handleLoad}
        autoPlay
        className={classNames('media-content', { loaded: isLoaded })}
      />
    )
  ) : campaign ? (
    <PlayerCampaignPreview
      campaign={campaign}
      isOpen={isOpen}
      onClose={onClose}
      onEnded={handleNextItem}
    />
  ) : internalNews ? (
    <InternalsNewsPreview
      news={internalNews}
      isOpen={isOpen}
      onClose={onClose}
      onEnded={handleNextItem}
      duration={currentItem.duration}
    />
  ) : externalUrl ? (
    <iframe
      title="External URL"
      src={externalUrl.url}
      onLoad={handleLoad}
      className="w-100 h-100"
      scrolling="no"
    />
  ) : null;
};

export default MediaItemPreview;
