import React, { useCallback, useState } from 'react';
import {
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Row,
  Spinner,
  Table
} from 'reactstrap';
import { useApiGet, useApiPost } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import PageSizeSelector from 'components/PageSizeSelector';
import Modal from 'components/Modal';
import { NavLink } from 'react-router-dom';
import Device from 'models/Device';
import License from 'models/License';
import logo1 from 'assets/images/logo1.svg';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';

type MutateDevice = {
  license?: string;
  machineId?: string;
  id?: string;
};

enum ModalTypes {
  availableLicenses = 'availableLicenses',
  allInUse = 'allInUse',
  pauseConfirmation = 'pauseConfirmation'
}

type ReattachDeviceFormValues = {
  device: Option;
};

declare const document: {
  getUniqueMachineId: () => string;
  setPlayerAuthData: (data: any) => Promise<void>;
};

const DeviceAttachPage = () => {
  const [showModalConfirmation, setShowModalConfirmation] = useState<
    ModalTypes
  >();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [filteredData, setFilteredData] = useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const [filterDevicesToReplace, setFilterDevicesToReplace] = useState<
    string
  >();

  const reattachDeviceFrom = useForm<ReattachDeviceFormValues>();

  const { data: device, isLoading } = useApiGet<Pagination<Device>>('/device', {
    params: filteredData
  });

  const { data: devicesToReplace, isLoading: isFetchingDevices } = useApiGet<
    Pagination<Device>
  >('/device', {
    params: {
      search: filterDevicesToReplace ? filterDevicesToReplace : undefined,
      pageSize: 200
    }
  });

  const { data: licenses } = useApiGet<License[]>('/customer/licenses', {
    params: filteredData
  });

  const { mutateAsync } = useApiPost<MutateDevice>(`/device`);

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const handleSelectDevice = useCallback(
    (device: Device) => {
      setSelectedDevice(device);

      const availableLicenses = licenses?.filter((license) => !license.inUse);

      if (device.inUse) {
        setShowModalConfirmation(ModalTypes.pauseConfirmation);
      } else if (availableLicenses && availableLicenses.length > 0) {
        setShowModalConfirmation(ModalTypes.availableLicenses);
      } else {
        setShowModalConfirmation(ModalTypes.allInUse);
      }
    },
    [licenses]
  );

  const closeModal = () => {
    setShowModalConfirmation(undefined);
  };

  const handleAttachDevice = useCallback(
    async (dataDevice: MutateDevice) => {
      const device = await mutateAsync(dataDevice);
      await document.setPlayerAuthData(device);
    },
    [mutateAsync]
  );

  const handleConfirm = useCallback(() => {
    if (showModalConfirmation === ModalTypes.availableLicenses) {
      const firstAvailableLicense = licenses?.find((license) => !license.inUse);
      handleAttachDevice({
        license: firstAvailableLicense?.id!,
        machineId: document.getUniqueMachineId(),
        id: `${selectedDevice?.id}/attach-player`
      });
    } else if (showModalConfirmation === ModalTypes.allInUse) {
      reattachDeviceFrom.handleSubmit(async (values) => {
        const selectedDeviceFrom = devicesToReplace?.items.find(
          (device) => device.id === values.device.value
        );
        handleAttachDevice({
          license: selectedDeviceFrom?.license?.id,
          machineId: document.getUniqueMachineId(),
          id: `${selectedDevice?.id}/attach-player`
        });
      })();
    } else if (showModalConfirmation === ModalTypes.pauseConfirmation) {
      handleAttachDevice({
        license: selectedDevice?.license?.id,
        machineId: document.getUniqueMachineId(),
        id: `${selectedDevice?.id}/attach-player`
      });
    }
  }, [
    showModalConfirmation,
    licenses,
    handleAttachDevice,
    selectedDevice?.id,
    selectedDevice?.license?.id,
    reattachDeviceFrom,
    devicesToReplace?.items
  ]);

  const isValidLicense = (license: License) => {
    return (
      license.active &&
      (!license.expirationDate ||
        moment(license.expirationDate).isAfter(moment()))
    );
  };

  return (
    <>
      <div>
        <Navbar
          color="white"
          fixed="top"
          container="fluid"
          light
          className="sticky-top d-flex justify-content-between border border-top-0 border-bottom-1 border-gray-200"
        >
          <NavbarBrand>
            <img src={logo1} height="30px" className="mx-auto" alt="logo" />
          </NavbarBrand>
          <NavLink
            className="me-5 text-reset text-decoration-none"
            to="/device/login"
          >
            Sair
          </NavLink>
        </Navbar>
        <Container fluid>
          <div className="row flex-nowrap">
            <Col className="w-100 p-0 bg-light">
              <main
                role="main"
                className="h-100 px-2 pt-2 pb-0 overflow-x-hidden overflow-y-scroll"
              >
                <div className="bg-white p-2 h-100">
                  <>
                    <Row className="d-flex align-items-center bg-white mb-2">
                      <Col className="bg-white">
                        <p className="mt-2">
                          <span className="h5 fw-bolder">
                            Selecione um dispositivo para ser vinculado ao
                            player
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center bg-white">
                      <Col className="bg-white mt-1" md="4">
                        <SearchInput
                          onSearchTextChange={handleSearch}
                          placeholder="Pesquisar dispositivos"
                        />
                      </Col>
                      <Col className="text-end mt-1">
                        <PageSizeSelector
                          pageSize={filteredData?.pageSize}
                          onPageSizeChanged={(pageSize) =>
                            setFilteredData((data) => ({
                              ...data,
                              pageSize
                            }))
                          }
                        />
                      </Col>
                    </Row>
                    <hr />
                    {isLoading ? (
                      <div className="text-center p-5">
                        <Spinner />
                      </div>
                    ) : device?.items.length ? (
                      <Table responsive hover className="border">
                        <tbody>
                          {device?.items.map((deviceList) => (
                            <tr
                              key={deviceList.id}
                              role="button"
                              onClick={() => handleSelectDevice(deviceList)}
                            >
                              <td>
                                <small>Nome: </small>
                                <br />
                                <b>{deviceList.name}</b>
                              </td>
                              <td>
                                <small>Grupo: </small>
                                <br />
                                <b>{deviceList.group?.name}</b>
                              </td>
                              <td>
                                <small>Local:</small>
                                <br />
                                <b>{deviceList.local}</b>
                              </td>
                              <td>
                                <small>Em uso:</small>
                                <br />
                                <b>{deviceList.inUse ? 'Sim' : 'Não'}</b>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p>
                        Você não possui licenças disponíveis. Por favor, entre
                        em contato com o administrador.
                      </p>
                    )}
                    {Boolean(device?.total) && (
                      <p className="text-end">
                        {device?.total} dispositivo(s) encontrado(s)
                      </p>
                    )}
                    {device && (
                      <PaginationNav
                        onChange={handlePageChange}
                        totalPages={device.totalPages}
                        currentPage={device.page}
                      />
                    )}
                  </>
                </div>
              </main>
            </Col>
          </div>
        </Container>
      </div>
      <Modal
        primaryButtonAction={handleConfirm}
        primaryButtonLabel="Confirmar"
        title="Confirmação"
        isOpen={!!showModalConfirmation}
        showCloseButton={false}
        toggle={closeModal}
        secondaryButtonAction={closeModal}
        secondaryButtonLabel="Cancelar"
        primaryButtonType={ModalTypes.allInUse ? 'submit' : 'button'}
      >
        {showModalConfirmation === ModalTypes.availableLicenses && (
          <p>
            Você tem {licenses.filter((license) => !license.inUse).length}{' '}
            licenças disponíveis. Ao continuar usará uma das licenças
            disponíveis.
          </p>
        )}
        {showModalConfirmation === ModalTypes.allInUse && (
          <FormProvider {...reattachDeviceFrom}>
            <p>
              Todas as suas licenças estão atualmente em uso. Para reutilizar
              uma licença neste novo player, selecione um dos dispositivos
              listados abaixo.
              <br />
              <small>
                Observação: O dispositivo selecionado terá sua licença
                desvinculada e será pausado.
              </small>
            </p>
            <AsyncTypeaheadField
              name="device"
              label="Dispositivo"
              placeholder={'Selecione um dispositivo'}
              options={
                devicesToReplace?.items
                  .filter(
                    (x) => x.inUse || (!!x.license && isValidLicense(x.license))
                  )
                  .map((device) => ({
                    value: device.id!,
                    label: device.name
                  })) || []
              }
              isLoading={isFetchingDevices}
              onSearch={setFilterDevicesToReplace}
              multiple={false}
            />
          </FormProvider>
        )}
        {showModalConfirmation === ModalTypes.pauseConfirmation && (
          <p>
            Você selecionou um dispositivo que está em uso. Ao confirmar, o
            player vinculado a esse dispositivo será pausado, e a licença será
            transferida para esse novo player.
          </p>
        )}
      </Modal>
    </>
  );
};

export default DeviceAttachPage;
