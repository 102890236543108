import axios, { AxiosRequestConfig, CanceledError } from 'axios';
import env from 'config/env';
import qs from 'qs';

export const constructURL = (path: string) => {
  const fullPath = env.API_ROOT_PATH + path;
  return fullPath;
};

export const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

export const _buildHeaders = (headers = {}) => {
  return {
    ...DEFAULT_HEADERS,
    ...headers,
    'X-CSRFToken': getCookie('csrftoken')
  };
};

export class NetworkUnauthorizedError extends Error {
  constructor(...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    this.name = 'NetworkUnauthorizedError';
  }
}

export class NetworkParamError extends Error {
  errors: any;

  constructor(errors: any, ...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    this.name = 'NetworkParamError';
    this.errors = errors;
  }
}

export const fnApiCall = (
  path: string,
  addToast: any,
  { method = 'get', headers = {}, data = null, ...options }: any
) => {
  const promise = axios(constructURL(path), {
    ...options,
    method,
    data,
    headers: _buildHeaders(headers),
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'brackets' }),
    withCredentials: true
  } as AxiosRequestConfig).catch((error) => {
    if (error instanceof CanceledError) {
      throw error;
    }
    if (
      ((error.response.status === 401 || error.response.status === 403) &&
        !path.startsWith('/auth/user')) ||
      (error.response.status === 404 && path === '/logout')
    ) {
      // If the response is 401 or 403 (and not part of authenticating (other
      // than token validation), invalidate the session
      throw new NetworkUnauthorizedError();
    } else if (error.response.status === 500) {
      // If its a 500 throw up a toast
      // FIXME De-duplicate these
      addToast({
        color: 'danger',
        message: 'Desculpe, houve um erro durante sua requisição.',
        icon: 'close'
      });
      throw new Error('Server Error');
    } else if (error.response.data) {
      // If its a "regular" error, pass the messages back to the user
      throw new NetworkParamError(error.response.data);
    }
  });

  return promise.then((response) => response?.data);
};
