import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import Icon from 'components/Icon';
import AdminUser from 'models/AdminUser';
import Modal from 'components/Modal';
import ImageUpload from 'components/ImageUpload';
import PasswordField from 'components/PasswordField';
import ImagePreview from 'components/ImagePreview';

const objetcSchema = {
  email: yup.string().email().required(),
  firstName: yup.string().required('O nome é obrigatório.'),
  lastName: yup.string().required('O sobrenome é obrigatório.'),
  password: yup.string().default(''),
  confirmPassword: yup.string().default('')
};
const yupSchemaEdit = yup.object(objetcSchema).required();

const yupSchemaCreate = yup.object({
  ...objetcSchema,
  password: yup.string().required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
});

const yupSchemaPassword = yup.object({
  password: yup.string().required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
});

const AdminUsersFormPage = () => {
  const params = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching, refetch } = useApiGet<AdminUser>(
    `/admin/auth/users`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<AdminUser>(
    '/admin/auth/signup_admin'
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<AdminUser>(
    '/admin/auth/users',
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/admin/auth/users',
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const isSaving = isCreating || isUpdating;

  const users = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(params.id ? yupSchemaEdit : yupSchemaCreate)
  });

  const formMethodsPassword = useForm({
    resolver: yupResolver(yupSchemaPassword)
  });

  const onError = useFieldSetErrors(formMethods.setError);
  const onErrorPassword = useFieldSetErrors(formMethodsPassword.setError);

  const handleOnSuccess = useCallback(() => {
    addToast({
      color: 'success',
      icon: 'check',
      message: 'Usuário salvo com sucesso'
    });
    !params.id && navigate('/admin/auth/users');
    params.id && setOpenPassword(false);
  }, [params.id, navigate, addToast]);

  const handleOnSubmit = useCallback(
    (data: any) => {
      if (params.id) {
        mutate(
          { ...data },
          {
            onError,
            onSuccess: handleOnSuccess
          }
        );
      } else {
        setOpenPassword(true);
      }
    },
    [params.id, mutate, onError, handleOnSuccess]
  );

  const handleSubmitModal = useCallback(
    (passwordFormData: any) => {
      formMethodsPassword.clearErrors();
      const formData = formMethods.getValues();

      if (params.id) {
        const newData = {
          ...passwordFormData,
          ...users
        };
        mutate(newData as any, {
          onError: (error: any) => {
            onError(error);
            onErrorPassword(error);
          },
          onSuccess: handleOnSuccess
        });
      } else {
        const combinedData = {
          ...formData,
          ...passwordFormData
        };

        mutate(combinedData as any, {
          onError: (error: any) => {
            onError(error);
            onErrorPassword(error);
          },
          onSuccess: handleOnSuccess
        });
      }
    },
    [
      mutate,
      onError,
      handleOnSuccess,
      onErrorPassword,
      formMethods,
      formMethodsPassword,
      params.id,
      users
    ]
  );

  const handleSavePassword = useCallback(() => {
    formMethodsPassword.clearErrors();
    formMethodsPassword.handleSubmit(handleSubmitModal)();
  }, [formMethodsPassword, handleSubmitModal]);

  const handleOnDelete = useCallback(() => {
    if (!params.id) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir usuário'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Usuário excluído com sucesso'
        });
        navigate('/admin/auth/users');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id]);

  useEffect(() => {
    if (users) {
      formMethods.reset({
        firstName: users.firstName,
        lastName: users.lastName,
        email: users.email
      } as any);
    }
  }, [users, formMethods]);

  const handleClickOpen = () => {
    setOpenPassword(true);
  };

  const handleClose = () => {
    setOpenPassword(false);
  };

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/admin/auth/users'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Usuários</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {params.id && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}

              <Button
                color={params.id ? 'secondary' : 'primary'}
                icon={params.id ? 'eye-fill' : 'floppy'}
                onClick={handleClickOpen}
                className="ms-2"
              >
                {params.id ? 'Alterar senha' : 'Continuar'}
              </Button>
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row>
              <Col className="col-sm-12 col-md-6 offset-md-3">
                <div className="mb-3">
                  {params.id && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <ImagePreview imageUrl={users?.picture} />
                      <ImageUpload
                        url={`/admin/auth/users/${params.id}/picture`}
                        onSuccess={() => refetch()}
                        keyName="image"
                      />
                    </div>
                  )}
                </div>
                <TextField
                  name="firstName"
                  label="Nome:"
                  placeholder="Nome"
                  defaultValue={users?.firstName}
                />
                <TextField
                  name="lastName"
                  label="Sobrenome:"
                  placeholder="Sobrenome"
                  defaultValue={users?.lastName}
                />
                <TextField
                  name="email"
                  label="Email:"
                  placeholder="Email"
                  defaultValue={users?.email}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir o usuário? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <Form onSubmit={() => {}} {...formMethodsPassword}>
        <Modal
          primaryButtonType="submit"
          primaryButtonAction={handleSavePassword}
          primaryButtonLabel="Salvar"
          title="Cadastre sua senha"
          isOpen={openPassword}
          showCloseButton={true}
          toggle={handleClose}
          isLoading={isSaving}
        >
          <PasswordField
            label="Senha:"
            type="password"
            name="password"
            placeholder="Senha"
          />
          <PasswordField
            label="Confirme a senha:"
            type="password"
            name="confirmPassword"
            placeholder="Confirme a senha"
          />
        </Modal>
      </Form>
    </>
  );
};
export default AdminUsersFormPage;
