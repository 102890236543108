import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from 'contexts/session';
import { ROUTES_PATHS } from 'navigations/routes';
import PageLoader from 'components/PageLoader';

declare const document: any;

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useContext(SessionContext);

  useEffect(() => {
    logout();
    if (document.getUniqueMachineId) {
      navigate(ROUTES_PATHS.AUTH_PAGES.LOGIN_DEVICE);
      return;
    }
    navigate(ROUTES_PATHS.AUTH_PAGES.LOGIN);
  }, [logout, navigate]);

  return <PageLoader />;
};

export default Logout;
